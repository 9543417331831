import React from 'react'
import { useHistory } from 'react-router-dom'
import { Stepper, Step, StepLabel, StepButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const Steps = ({
  current,
  businessId,
  serviceId,
}: {
  current: number
  businessId?: string
  serviceId?: string
}) => {
  const { t } = useTranslation()
  const steps = [t('component.steps.step1'), t('component.steps.step2'), t('component.steps.step3')]
  const history = useHistory()

  const handleStep = (index: number) => {
    if (index === 0) {
      // link to business
      history.push('/business/' + businessId)
    }
    if (index === 1) {
      // index to date
      history.push('/business/' + businessId + '/' + serviceId)
    }
    if (index === 2) {
      // index to meet draft, if already present
      history.push('')
    }
  }

  const render = () => {
    return steps.map((step, i) => {
      if (i < current) {
        return (
          <Step key={i} completed={true}>
            <StepButton onClick={() => handleStep(i)}>
              <StepLabel>{step}</StepLabel>
            </StepButton>
          </Step>
        )
      } else if (i === current) {
        return (
          <Step key={i} active completed={false}>
            <StepLabel>{step}</StepLabel>
          </Step>
        )
      } else if (i > current) {
        return (
          <Step key={i} completed={false} disabled={true}>
            <StepLabel>{step}</StepLabel>
          </Step>
        )
      }
      return null
    })
  }

  return (
    <Stepper alternativeLabel activeStep={current}>
      {render()}
    </Stepper>
  )
}

export default Steps
