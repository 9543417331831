import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './i18n/en.json'
import fr from './i18n/fr.json'
import de from './i18n/de.json'

import { Settings } from 'luxon'
import i18next from 'i18next'

// set language on initial load
// use the first defined language as defaultm which should be the user's main
const userNavigatorLanguage = navigator.languages[0]
Settings.defaultLocale = userNavigatorLanguage
// listen to language change
i18next.on('languageChanged', (lng) => {
  Settings.defaultLocale = lng
})

// translation files
const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  de: {
    translation: de,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // use the user's browser first language
    lng: userNavigatorLanguage,
    fallbackLng: ['en', 'fr', 'de'],

    keySeparator: '.', // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
