import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Button,
  useTheme,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { DatePicker } from '@material-ui/pickers'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { queryBook, querySearchDate } from 'queries'
import { DateTime } from 'luxon'
import { MeeMeet } from 'types'
import { Steps, Host, Service } from 'components'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { formatISO9075 } from 'date-fns'
import CircularProgressThemed from 'components/circular-progress-themed'
import { BusinessItem } from 'components/businesItem'
import AreaTitle from 'components/area-title'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    alignContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    marginBottom: theme.spacing(10),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(5),
  },

  // responsive-vertical-listing-in-multiple-columns-with-floating-div
  bookedListGrouppedContainer: {
    columnWidth: '5em',
    margin: 10,
    padding: 5,
    display: 'flex',
    flexWrap: 'wrap',
  },
  bookedListItem: {
    margin: 5,
    maxWidth: '6em',
    marginBottom: 20,
    pageBreakInside: 'avoid',
    breakInside: 'avoid-column',
  },
}))

interface BookRouteProps {
  businessId: string
  serviceId: string
}

const DateSelect = ({
  // meets,
  selectedDate,
  setSelectedDate,
  disabledDate,
}: {
  // meets: MeeMeet[] | undefined
  selectedDate: Date | undefined
  setSelectedDate: Function
  disabledDate: ((day: MaterialUiPickersDate) => boolean) | undefined
}) => {
  return (
    <DatePicker
      autoOk
      disablePast={true}
      variant="static"
      openTo="date"
      value={selectedDate}
      onChange={(date) => {
        // Luxon DateTime
        // setSelectedDate(date?.toJSDate())
        // date-fns
        setSelectedDate(date)
      }}
      shouldDisableDate={disabledDate}
    />
  )
}

const BookList = ({ meets }: { meets: MeeMeet[] | undefined }) => {
  const classes = useStyles()

  const history = useHistory()
  /**
   * Select a meeting to book.
   * Save a stringified version to the local storage.
   * @param meet the meeting to select
   */
  const select = (meet: MeeMeet) => {
    // an alternative would be to pass this in the URL
    localStorage.setItem('meet', JSON.stringify(meet))
    history.push('/meet')
  }
  if (!meets && !Array.isArray(meets)) return null

  // group meets by users
  const groupedMeets = meets.reduce((acc, meet) => {
    const hosts = meet.users.filter((user) => user.role === 'host')
    if (!acc[hosts[0].contactId]) {
      acc[hosts[0].contactId] = []
    }
    acc[hosts[0].contactId].push(meet)
    return acc
  }, {} as { [key: string]: MeeMeet[] })

  // example of data for `groupedMeets`
  // {
  //     "groupedMeets": {
  //         "5662bfc4b41216297f8ab0e37d01c042": [
  //             {
  //                 "_id": "837a14ae-7c4f-4fc5-ae05-73fdbf144df2",
  //                 "type": "booking",
  //                 "serviceId": "e61e9260-f853-11ec-9a62-eb91077f6acb",
  //                 "users": [
  //                     {
  //                         "role": "host",
  //                         "contactId": "5662bfc4b41216297f8ab0e37d01c042"
  //                     }
  //                 ],
  //                 "businessId": "3eab01a0-e3bd-11ea-b2ff-2b47a7a099b5",
  //                 "dateFrom": 1670659200000,
  //                 "dateFromHuman": "2022-12-10T09:00:00.000+01:00",
  //                 "dateTo": 1670661000000,
  //                 "dateToHuman": "2022-12-10T09:30:00.000+01:00",
  //                 "price": 0,
  //                 "note": ""
  //             },
  //             {
  //                 "_id": "b7ff52dc-020b-4f32-8d5b-bcc62a652d24",
  //                 "type": "booking",
  //                 "serviceId": "e61e9260-f853-11ec-9a62-eb91077f6acb",
  //                 "users": [
  //                     {
  //                         "role": "host",
  //                         "contactId": "5662bfc4b41216297f8ab0e37d01c042"
  //                     }
  //                 ],
  //                 "businessId": "3eab01a0-e3bd-11ea-b2ff-2b47a7a099b5",
  //                 "dateFrom": 1670661000000,
  //                 "dateFromHuman": "2022-12-10T09:30:00.000+01:00",
  //                 "dateTo": 1670662800000,
  //                 "dateToHuman": "2022-12-10T10:00:00.000+01:00",
  //                 "price": 0,
  //                 "note": ""
  //             },
  //            [...]
  //         ]
  //     }
  // }

  return (
    <List>
      {Object.keys(groupedMeets).map((contactId) => {
        return (
          <>
            <div>
              <Host meet={groupedMeets[contactId][0]} />
            </div>
            <div className={classes.bookedListGrouppedContainer}>
              {groupedMeets[contactId].map((meet) => {
                return (
                  <Button
                    onClick={() => select(meet)}
                    variant="contained"
                    color="primary"
                    startIcon={<AccessTimeIcon />}
                    className={classes.bookedListItem}
                  >
                    {DateTime.fromMillis(meet.dateFrom).toLocaleString(DateTime.TIME_24_SIMPLE)}
                  </Button>
                )
              })}
            </div>
          </>
        )
      })}
    </List>
  )
}

const Book: React.FC<RouteComponentProps<BookRouteProps>> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const { businessId, serviceId } = props.match.params
  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { isLoading, data: meets } = useQuery(
    ['meet', { businessId, serviceId, selectedDate }],
    () => queryBook(businessId, serviceId, selectedDate),
    { enabled: !!selectedDate },
  )

  // used to define disabled dates
  const { isLoading: isLoadingDate, data: dates } = useQuery(
    ['date', { businessId }],
    () => querySearchDate(businessId),
    { enabled: !!businessId },
  )

  React.useEffect(() => {
    if (selectedDate) {
      queryClient.invalidateQueries('meet')
    }
  }, [selectedDate, queryClient])

  React.useEffect(() => {
    if (selectedDate) {
      localStorage.selectedDate = selectedDate as Date
    }
  }, [selectedDate])

  React.useEffect(() => {
    if (localStorage.selectedDate !== selectedDate) {
      setSelectedDate(new Date(localStorage.selectedDate))
    } else {
      setSelectedDate(new Date())
    }
  }, [])

  const disabledDate = (day: MaterialUiPickersDate) => {
    if (day && dates) {
      return dates.indexOf(formatISO9075(day, { representation: 'date' })) === -1
    }
    return false
  }

  return (
    <Container fixed className={classes.container}>
      <Typography variant="h5" align="center">
        {t('book.title')}
      </Typography>
      <Steps current={1} businessId={businessId} serviceId={serviceId} />
      <Grid container spacing={isSmall ? 0 : 3}>
        <Grid item xs={12} md={6}>
          <AreaTitle>{t('book.business')}</AreaTitle>
          <Paper>
            <BusinessItem businessId={businessId} />
          </Paper>

          <AreaTitle>{t('book.service')}</AreaTitle>
          <Paper>
            <Service
              businessId={businessId}
              serviceId={serviceId}
              icon={<EditIcon />}
              to={`/business/${businessId}`}
              editable
            />
          </Paper>

          <AreaTitle>{t('book.dates')}</AreaTitle>
          <Paper>
            <DateSelect
              // meets={meets}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              disabledDate={disabledDate}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <AreaTitle>{t('book.hours')}</AreaTitle>
          <Paper>
            <Typography variant="subtitle1">
              {selectedDate && DateTime.fromJSDate(selectedDate).toLocaleString(DateTime.DATE_MED)}
            </Typography>
            {!isLoading && (
              <Typography variant="subtitle2" color="textSecondary">
                {meets?.length || 0} option(s)
              </Typography>
            )}
            {isLoading && <CircularProgressThemed />}
            <BookList meets={meets} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Book
