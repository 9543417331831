import {
  Avatar,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import { config } from 'config'
import { queryContact, queryUser } from 'queries'
import React from 'react'
import { useQuery } from 'react-query'
import { MeeMeet } from 'types'
import { Link } from 'react-router-dom'
import { LinkItemEditable } from './link-item-editable'

/**
 * TODO: create helper for images
 * https://meetme.rokka.io/large/71376b6e06344b95f5405c276c5b68e2e4a41d53.jpg
 * https://meetme.rokka.io/medium/71376b6e06344b95f5405c276c5b68e2e4a41d53.jpg
 * https://meetme.rokka.io/small/71376b6e06344b95f5405c276c5b68e2e4a41d53.jpg
 * https://meetme.rokka.io/xlarge/71376b6e06344b95f5405c276c5b68e2e4a41d53.jpg
 */
export const User = ({
  userId,
  size,
  description,
}: {
  userId: string
  size: number
  description: string
}) => {
  const { isLoading, data: user } = useQuery(['user', { userId }], () => queryUser(userId))
  // 40 is the default size of the thumbnail
  if (isLoading) {
    return <CircularProgress />
  }
  return (
    // we use the linkitemeditable component to have the same style as the date and service items
    <ListItem style={{ flexBasis: '25%' }}>
      <ListItemAvatar>
        <Avatar
          alt={user?.firstName}
          src={`${config.rokka}/small/${user?.image?.hash}.jpg`}
          style={{ height: size, width: size }}
        />
      </ListItemAvatar>
      <ListItemText primary={user && `${user?.firstName}`} secondary={description} />
    </ListItem>
  )
}

export const Person = ({ contact }) => {
  if (!contact) return null
  const size = 30
  const url = `https://eu.ui-avatars.com/api/?background=30CC57&name=${contact.firstName}`
  return (
    <ListItem style={{ flexBasis: '25%' }}>
      <ListItemAvatar>
        <Avatar style={{ height: size, width: size }} src={url} />
      </ListItemAvatar>
      <ListItemText primary={contact?.firstName} />
    </ListItem>
  )
}

User.defaultProps = {
  size: 40,
  description: '',
}

/**
 * Render the contact
 *
 */
export const Contact = ({ contactId }: { contactId: string }) => {
  const { data: contact } = useQuery(['contact', contactId], () => queryContact(contactId), {
    enabled: !!contactId,
  })
  if (!contact || !contact.userId) return <Person contact={contact} />
  // TODO: add the role
  return <User userId={contact.userId} />
}

export const Participant = ({ meet }: { meet: MeeMeet }) => {
  const users = meet.users.filter((user) => user.role === 'participant')
  let contactId
  if (users.length > 0) {
    // TODO: handle multiple participant display
    contactId = users[0].contactId
  }
  if (!contactId) return null
  return <Contact contactId={contactId} />
}

export const Host = ({ meet }: { meet: MeeMeet }) => {
  const users = meet.users.filter((user) => user.role === 'host')
  let contactId
  // single host
  if (users.length === 1) {
    return <Contact contactId={users[0].contactId} />
  }
  // multi host, when service `numberOfEmployeeRequired` is > 1
  if (users.length > 1) {
    // displayed more to ensure we can support multiple overflow
    return (
      <List style={{ display: 'flex', flexWrap: 'wrap' }}>
        {users.map((user) => (
          <Contact contactId={user.contactId} />
        ))}
      </List>
    )
  }
  // we should not get here ^__^
  return null
}
