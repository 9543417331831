import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@material-ui/icons/Language'

export default function LanguageSwitcher() {
  const { t, i18n } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  const switchLanguage = (language: string) => {
    i18n.changeLanguage(language)
    handleClose()
  }

  return (
    <div>
      <Button
        startIcon={<LanguageIcon />}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {t('languageSwitcher.choose')}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => switchLanguage('fr')}>{t('languageSwitcher.fr')}</MenuItem>
        <MenuItem onClick={() => switchLanguage('de')}>{t('languageSwitcher.de')}</MenuItem>
        <MenuItem onClick={() => switchLanguage('en')}>{t('languageSwitcher.en')}</MenuItem>
      </Menu>
    </div>
  )
}
