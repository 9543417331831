import React, { useState } from 'react'
import { UserContext } from 'contexts/userContext'
import { MeeUser } from 'types'
import { api } from 'queries'
import { Login } from 'components'

export const UserContextProvider = (props: any) => {
  const [user, setUser] = useState<MeeUser | undefined>(undefined)
  const [jwt, setJwt] = useState<string | undefined>(undefined)
  const [showLogin, setShowLogin] = useState<boolean>(false)

  React.useEffect(() => {
    if (user) {
      localStorage.user = JSON.stringify(user)
    }
  }, [user])

  React.useEffect(() => {
    if (localStorage.user) {
      setUser(JSON.parse(localStorage.user))
    }
  }, [])

  React.useEffect(() => {
    if (jwt) {
      localStorage.jwt = JSON.stringify(jwt)
      api.setHeader('Authorization', `Bearer ${jwt}`)
    }
  }, [jwt])

  React.useEffect(() => {
    if (localStorage.jwt) {
      setJwt(JSON.parse(localStorage.jwt))
    }
  }, [])

  const logout = () => {
    setUser(undefined)
    setJwt(undefined)
    localStorage.clear()
  }

  return (
    <UserContext.Provider value={{ user, setUser, jwt, setJwt, logout, showLogin, setShowLogin }}>
      {props.children}
      {/* Put this at the end to ensure the forms can be modified */}
      {showLogin && <Login />}
    </UserContext.Provider>
  )
}
