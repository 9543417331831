import { ApiResponse, create } from 'apisauce'
import { MeeBusiness, MeeContact, MeeMeet, MeeService, MeeUser } from 'types'
import { DateTime } from 'luxon'
import { config } from 'config'

export const api = create({
  baseURL: config.api,
})

/**
 * Handle error, throwing the error data when an error occurs or returning the
 * response if all good.
 * @param response the response from apisauce
 */
export const handleError = (response: ApiResponse<unknown, unknown>) => {
  if (!response.ok) {
    // throw the error, notice we don't throw an *Error* object!
    throw response.data
  }
  return response
}

/**
 * Mutations queries
 */

export const mutationUser = (user: Partial<MeeUser>) => api.patch('/user/' + user._id, { ...user })

export const mutationBook = (meet: MeeMeet) => api.post('/book', { ...meet })

/**
 * Standard queries
 */

//  this is it
export const queryHello = (phone: string) =>
  api
    .post('/hello', { phone })
    .then(handleError)
    .then((response) => response.data)

export const queryHelloCode = (phone: string, code: string) =>
  api
    .post('/hello/code', { phone, code })
    .then(handleError)
    .then((response) => response.data)

export const queryBusiness = (id: string) =>
  api
    .get('/business/' + id)
    .then(handleError)
    .then((response) => response.data as MeeBusiness)

export const queryEmployees = (businessId: string) =>
  api
    .get('/contact/employees/' + businessId)
    .then(handleError)
    // @ts-ignore TODO: normalize the data returned by the API
    .then((response) => response.data.data as MeeContact[])

export const queryContact = (id: string) =>
  api
    .get('/contact/' + id)
    .then(handleError)
    .then((response) => response.data as MeeContact)

export const queryUser = (id: string) =>
  api
    .get('/user/' + id)
    .then(handleError)
    .then((response) => response.data as MeeUser)

export const queryMeet = (id: string) =>
  api
    .get('/meet/' + id)
    .then(handleError)
    .then((response) => {
      return response.data as MeeMeet
    })

export const queryService = (id: string) =>
  api
    .get('/service/' + id)
    .then(handleError)
    .then((response) => response.data as MeeService)

export const queryServices = (ids?: string[]) =>
  api
    .get(
      // this works
      // "/service?_id=service-1",

      // this generates server error
      // "/service?$skip=1",

      // this generates server error
      // "/service?_id[$in]='service-1'",

      'service',
      // {
      //   query: {
      //     _id: {
      //       $in: ["service-1"],
      //     },
      //   },
      // }

      // {
      // query: {
      //   selector: {
      //     id: "service-1",
      //   },
      // },
      // query: {
      // params: {
      // selector: {
      // _id: { $eq: "service-1" },
      // },
      // limit: 1,
      // },
      // },
      // this breaks the backend
      // $limit: "1",
      // }

      // ALL THESE NOT WORKING - 45min of tests..
      // {
      // {
      //   params: {
      //     query: { _id: { $eq: "service-2" } },
      //   },
      // }
      // $and: [{ _id: { $eq: "service-1" } }],
      // query: {
      // $limit: 2,
      // },
      // }

      // {
      // query: {
      // $limit: 2, // not working
      // _id: {
      //   $in: ["service-1"],
      // },
      // query: { _id: { $in: ["service-1"] } },
      // example :
      // { query: { _id: { $in: serviceIds } } });
      // },
      // }
    )
    .then(handleError)
    // @ts-ignore FIXME: ugly ignore here - how to get multiple data properly from our API?
    .then((response) => response.data.data as MeeService[])

export const queryBook = (
  businessId: string,
  serviceId: string,
  selectedDate?: Date,
): MeeMeet[] => {
  if (!selectedDate) return []

  const dateFrom = DateTime.fromJSDate(selectedDate).startOf('day').toMillis()
  const dateTo = DateTime.fromJSDate(selectedDate).endOf('day').toMillis()
  // @ts-ignore
  return api
    .get('/book/', {
      businessId,
      serviceId,
      // example
      // dates: "[{\"from\": "1608817790695", \"to\": 1624485600000}]",
      dates: `[{"from": "${dateFrom}", "to": "${dateTo}"}]`,
    })
    .then(handleError)
    .then((response) => {
      // @ts-ignore FIXME: ugly ignore, idem as above
      return response.data.data as MeeMeet[]
    })
}

export const querySearchDate = (businessId: string) => {
  if (!businessId) return []
  return api
    .get('/search/date', { businessId })
    .then(handleError)
    .then((response) => {
      return response.data as string[]
    })
}
