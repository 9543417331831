import React from 'react'
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Business, Service, Steps, Error, ProcessBooking } from 'components'
import { MeeMeet } from 'types'
import EventIcon from '@material-ui/icons/Event'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { DateTime } from 'luxon'
import { Link, NavLink } from 'react-router-dom'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { useQueries, useQuery } from 'react-query'
import { queryMeet } from 'queries'
import { useUser } from 'contexts/userContext'
import AreaTitle from 'components/area-title'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { Contact } from 'components/user'
import { LinkItemEditable } from 'components/link-item-editable'
import { Comments } from 'components/comments'

const useStyles = makeStyles((theme) => ({
  container: {
    alignContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    marginBottom: theme.spacing(10),
  },
  cardAction: {
    padding: theme.spacing(1),
  },
}))

/**
 * Display the date
 */
const Date = ({ meet, dates }: { meet: MeeMeet; dates: { dateFrom: number; dateTo: number } }) => {
  const { t } = useTranslation()
  const history = useHistory()
  if (!meet) return null
  const url = '/business/' + meet.businessId + '/' + meet.serviceId
  const editable = meet.type === 'booking'
  return (
    <LinkItemEditable editable={editable} keyId={'lie-' + meet._id} component={Link} to={url}>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <EventIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={DateTime.fromMillis(dates.dateFrom).toLocaleString(DateTime.DATE_HUGE)}
          secondary={`${DateTime.fromMillis(dates.dateFrom).toLocaleString(
            DateTime.TIME_24_SIMPLE,
          )} - 
          ${DateTime.fromMillis(dates.dateTo).toLocaleString(DateTime.TIME_24_SIMPLE)}`}
        ></ListItemText>
        {editable && (
          <ListItemAvatar>
            <Avatar>
              <EditIcon onClick={() => history.push(url)} aria-label="edit-date" />
            </Avatar>
          </ListItemAvatar>
        )}
      </ListItem>
    </LinkItemEditable>
  )
}

/**
 * Given a list of meets, will render a list of unique participant, removing
 * any duplicate in the process.
 * We keep the `role`
 */
const Participants = ({ meets }) => {
  // @ts-ignore FIXME: ts
  const [uniqueContacts, setUniqueContacts] = React.useState<MeeMeet['users']>([])

  React.useEffect(() => {
    if (meets && Array.isArray(meets)) {
      // @ts-ignore FIXME: ts
      const relations: MeeMeet['users'] = []
      meets.map((meet) => {
        if (meet && meet.users) {
          meet.users.map((relation) => {
            if (!relations.find(({ contactId }) => contactId === relation.contactId)) {
              relations.push(relation)
            }
          })
        }
      })
      setUniqueContacts(relations)
    }
  }, [meets])
  return (
    <List>
      <List>
        {uniqueContacts.map((relation) => (
          <Contact key={'uc-' + relation.contactId} contactId={relation.contactId} />
        ))}
      </List>
    </List>
  )
}

/**
 * Take a meet and load all its links
 */
const LoadMeetLinks = ({ meet, meets, setMeets }) => {
  // load the linked meets
  const meetsQueries = useQueries(
    meet.links.map((meetId) => {
      return {
        queryKey: ['meet', meetId],
        queryFn: () => queryMeet(meetId),
      }
    }),
  )

  // use the callback to set the meets loaded
  React.useEffect(() => {
    if (meetsQueries) {
      const linkedMeets: MeeMeet[] = []
      meetsQueries.map(({ data }) => {
        linkedMeets.push(data as MeeMeet /* cast */)
      })
      setMeets([meet, ...linkedMeets])
    }
  }, [meetsQueries])

  return null
}

interface MeetRouteProps {
  meetId: string
}

/**
 * Calculate dateFrom and dateTo for a group of meets
 */
const calculateDates = (meets: MeeMeet[]) => {
  let dateFrom = Number.MAX_SAFE_INTEGER
  let dateTo = Number.MIN_SAFE_INTEGER
  // if (meets && Array.isArray(meets) && meets.length > 0) {
  for (let i = 0; i < meets.length; i++) {
    const meet = meets[i]
    if (meet) {
      if (meet.dateFrom < dateFrom) {
        dateFrom = meet.dateFrom
      }
      if (meet.dateTo > dateTo) {
        dateTo = meet.dateTo
      }
    }
  }
  // }
  return {
    dateFrom,
    dateTo,
  }
}

const Meet: React.FC<RouteComponentProps<MeetRouteProps>> = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useTranslation()
  /**
   * Our main meet
   */
  const [meet, setMeet] = React.useState<MeeMeet>()
  /**
   * The meets with its linked meets
   */
  const [meets, setMeets] = React.useState<MeeMeet[]>([])
  /**
   * The linked meets ID
   */
  const { meetId } = props.match.params
  const { user, setShowLogin } = useUser()

  // our main meet
  const { isLoading, refetch, data, error, isError } = useQuery<MeeMeet, Error>(
    ['meet', meetId],
    () => queryMeet(meetId),
    {
      // trigger only manually
      enabled: false,
      // TODO: debug only, faster to throw error on purpose
      // retry: 1,
    },
  )

  // on initial load
  React.useEffect(() => {
    // get from the storage
    const meetStorage = localStorage.getItem('meet')
    if (meetStorage) {
      setMeet(JSON.parse(meetStorage))
    }
    // get from the URL
    if (meetId) {
      setTimeout(() => {
        refetch()
      }, 100)
    }
  }, [refetch, meetId])

  // when the data has been fetched
  React.useEffect(() => {
    if (!isError && data) {
      setMeet(data)
    }
  }, [data])

  // When single meet booked
  React.useEffect(() => {
    if (data) {
      setMeet(data)
    }
  }, [data])

  // ensure the meets contain the main meeting too
  React.useEffect(() => {
    if (meet) {
      // set our meets with the main meet found
      setMeets([meet])
    }
  }, [meet])

  /**
   * Error handling
   */
  if (isError && error) {
    return <Error error={error} />
  }

  if (isLoading)
    return (
      <Container fixed className={classes.container}>
        <CircularProgress />
      </Container>
    )

  if (!meet) return null

  // TODO: split this in two separata components
  const cardActions = () => {
    if (meet.status === 'confirmed') {
      return (
        <Chip
          icon={<DoneIcon />}
          label={t('meet.status.confirmed')}
          color="primary"
          size="medium"
          clickable={false}
        />
      )
    }
    if (meet.status === 'cancelled') {
      return (
        <Chip
          icon={<CancelIcon />}
          label={t('meet.status.cancelled')}
          color="secondary"
          size="medium"
          clickable={false}
        />
      )
    }
    if (meetId) return null
    // TODO: this will crash if the user is new and don't has a `firstName` set
    if (user && user?.firstName?.length > 1) {
      return <ProcessBooking meet={meet} />
    } else {
      return (
        <Button variant="contained" color="primary" onClick={() => setShowLogin(true)}>
          {t('component.login.bookNow')}
        </Button>
      )
    }
  }

  return (
    <Container fixed className={classes.container}>
      {meet?.links && (
        <>
          <LoadMeetLinks meet={meet} meets={meets} setMeets={setMeets} />
        </>
      )}
      {!meetId && (
        <div>
          <Typography variant="h5" align="center">
            {t('book.title')}
          </Typography>
          <Steps current={2} businessId={meet.businessId} serviceId={meet.serviceId} />
        </div>
      )}
      <Grid container spacing={isSmall ? 0 : 3}>
        {meet?.status === 'confirmed' && (
          <Grid xs={12} md={12}>
            <CheckCircleOutlineIcon style={{ height: 90, width: 90, color: 'green' }} />
            <Typography color="textPrimary" variant="h6">
              {t('meet.status.confirmed')}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <AreaTitle>{t('book.business')}</AreaTitle>
          <Business businessId={meet?.businessId} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AreaTitle>{t('meet.meeting')}</AreaTitle>
          <Card>
            <Date meet={meet} dates={calculateDates(meets)} />
            <Divider />
            {meets.map(
              (meet) =>
                meet && (
                  <Service
                    key={'s-' + meet.serviceId}
                    businessId={meet.businessId}
                    serviceId={meet.serviceId}
                    icon={<EditIcon />}
                    to={`/business/${meet.businessId}`}
                    editable={meet.type === 'booking'}
                    // indicate if we are showing a group of meets
                    group={meets.length > 1}
                  />
                ),
            )}
            <Divider />
            <Participants meets={meets} />
            {/* Add the actions and display meet status */}
            <CardActionArea className={classes.cardAction}>
              <CardActions>{cardActions()}</CardActions>
            </CardActionArea>
            <CardContent>
              <Typography variant="caption">
                <NavLink to="/">{t('meet.pushUseApp')}</NavLink>
              </Typography>
            </CardContent>
          </Card>
          {meets.map((meet) => (
            <Comments comments={meet.comments} />
          ))}
          <Typography variant="caption" color="textSecondary">
            {meet._id}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Meet
