import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import PhoneInput from 'react-phone-input-2'
import { useMutation, useQuery } from 'react-query'
import { mutationUser, queryHello, queryHelloCode } from 'queries'
import { FormControl, makeStyles, TextField, Typography } from '@material-ui/core'
import 'react-phone-input-2/lib/style.css'
import { useUser } from 'contexts/userContext'
import CircularProgressThemed from './circular-progress-themed'
import { MeeUser } from 'types'
import { useTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles((theme) => ({
  textfield: {
    paddingBottom: theme.spacing(3),
  },
}))

const Hello = ({
  nextStep,
  handleClose,
  phone,
  setPhone,
}: {
  nextStep: Function
  handleClose: Function
  phone: string
  setPhone: Function
}) => {
  const { t } = useTranslation()
  const { isSuccess, isLoading, data, refetch, error, isError } = useQuery(
    'hello',
    () => queryHello(phone),
    {
      // trigger only manually
      enabled: false,
    },
  )

  React.useEffect(() => {
    // TODO: add phone validation or error from backend (Twilio SMS delivery check?)
    // use the twilio lookup API - https://www.twilio.com/docs/lookup/api
    // TODO: handle too many request error
    if (data) {
      // @ts-ignore
      if (data?.ok) {
        if (isSuccess) {
          nextStep()
        }
      }
    }
  }, [data, isSuccess])

  if (isLoading)
    return (
      <React.Fragment>
        <DialogContent>
          <CircularProgressThemed />
        </DialogContent>
      </React.Fragment>
    )

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('component.login.dialog')}
        </DialogContentText>
        <PhoneInput
          key="inputPhone"
          country={'ch'}
          onChange={(value) => setPhone('+' + value)}
          inputStyle={{ width: '80%', maxWidth: 250 }}
        />
        {isError && (
          <Typography variant="caption" color="error">
            {/* @ts-ignore TODO: ts type */}
            {error?.message} - {t('component.login.errorPhone')}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => refetch()}>
          {t('component.login.send')}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          {t('component.login.cancel')}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

const HelloCode = ({
  nextStep,
  handleClose,
  code,
  setCode,
  phone,
}: {
  nextStep: Function
  handleClose: Function
  code: string
  setCode: Function
  phone: string
}) => {
  const { t } = useTranslation()
  const { setUser, setJwt } = useUser()
  const { isLoading, refetch, isError, error } = useQuery(
    'helloCode',
    () => queryHelloCode(phone, code),
    {
      // trigger only manually
      enabled: false,
      onSuccess: (data) => {
        // @ts-ignore use ts type
        const { jwt, user }: { jwt: string; user: MeeUser } = data
        setUser(user)
        setJwt(jwt)
        // check firstName and lastName are properly filled
        if (
          user.firstName &&
          user.lastName &&
          user.firstName.length > 1 &&
          user.lastName.length > 1
        ) {
          handleClose()
        } else {
          nextStep()
        }
      },
    },
  )

  if (isLoading)
    return (
      <React.Fragment>
        <DialogContent>
          <CircularProgressThemed />
        </DialogContent>
      </React.Fragment>
    )

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('component.login.enterCode')}
        </DialogContentText>
        <TextField
          key="inputCode"
          variant="outlined"
          size="small"
          label="code"
          type="number"
          error={isError}
          // @ts-ignore TODO: intl with i18n
          helperText={error?.message}
          onChange={(event) => setCode(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => refetch()}>
          {t('component.login.confirm')}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          {t('component.login.cancel')}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

const HelloUser = ({
  handleClose,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
}: {
  nextStep: Function
  handleClose: Function
  firstName: string
  setFirstName: Function
  lastName: string
  setLastName: Function
  email: string
  setEmail: Function
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { user, setUser } = useUser()
  const mutation = useMutation((user: Partial<MeeUser>) => mutationUser(user))
  const mutateUser = () => {
    if (
      firstName !== '' &&
      firstName.length > 0 &&
      lastName !== '' &&
      lastName.length > 0 &&
      email !== '' &&
      email.length > 5
    ) {
      const updatedUser: Partial<MeeUser> = { ...user, firstName, lastName, email }
      // we don't want the `_rev` prop to patch the user, otherwise it creates an error
      delete updatedUser._rev
      mutation.mutate(updatedUser)
      // save in our parent component
      setUser(updatedUser)
      handleClose()
    } else {
      alert(t('component.login.pleaseFillForm'))
    }
  }
  if (mutation.isLoading)
    return (
      <React.Fragment>
        <DialogContent>
          <CircularProgressThemed />
        </DialogContent>
      </React.Fragment>
    )

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('component.login.form.dialog')}
        </DialogContentText>
        <FormControl>
          <TextField
            key="inputFirstName"
            variant="outlined"
            size="small"
            label={t('component.login.form.firstname')}
            onChange={(event) => setFirstName(event.target.value)}
            className={classes.textfield}
          />
          <TextField
            key="inputLastName"
            variant="outlined"
            size="small"
            label={t('component.login.form.lastname')}
            onChange={(event) => setLastName(event.target.value)}
            className={classes.textfield}
          />
          <TextField
            key="inputEmail"
            variant="outlined"
            size="small"
            label={t('component.login.form.email')}
            onChange={(event) => setEmail(event.target.value)}
            className={classes.textfield}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => mutateUser()}>
          {t('component.login.confirm')}
        </Button>
        <Button onClick={() => handleClose()} color="primary">
          {t('component.login.cancel')}
        </Button>
      </DialogActions>
    </React.Fragment>
  )
}

export const Login = () => {
  const { t } = useTranslation()

  /**
   * Define the step
   * 0 = enter phone number
   * 1 = enter code
   * 2 = enter firstname and lastname
   */
  const [step, setStep] = React.useState<number>(0)
  const [phone, setPhone] = React.useState<string>('')
  const [code, setCode] = React.useState<string>('')

  const [firstName, setFirstName] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')
  const [email, setEmail] = React.useState<string>('')

  const { showLogin, setShowLogin } = useUser()

  const handleClose = () => {
    setShowLogin(false)
  }

  const contentBasedOnStep = () => {
    if (step === 0) {
      return (
        <Hello
          nextStep={() => setStep(1)}
          handleClose={handleClose}
          phone={phone}
          setPhone={setPhone}
        />
      )
    } else if (step === 1) {
      return (
        <HelloCode
          nextStep={() => setStep(2)}
          handleClose={handleClose}
          phone={phone}
          code={code}
          setCode={setCode}
        />
      )
    } else if (step === 2) {
      return (
        <HelloUser
          nextStep={() => setStep(3)}
          handleClose={handleClose}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
        />
      )
    }
  }

  return (
    <div>
      <Dialog
        open={showLogin}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="alert-dialog-slide-title">{t('component.login.confirm')}</DialogTitle>
        {contentBasedOnStep()}
      </Dialog>
    </div>
  )
}
