import { createMuiTheme } from '@material-ui/core'

/**
 * Check the docs
 * https://material-ui.com/customization/color/#picking-colors
 *
 **/
export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1386eb',
    },
    secondary: {
      main: '#F45B69',
    },
  },
})
