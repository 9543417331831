import { createContext, useContext } from 'react'
import { MeeUser } from 'types'

export const UserContext = createContext<{
  user: MeeUser | undefined
  setUser: Function
  jwt: string | undefined
  setJwt: Function
  logout: Function
  showLogin: boolean
  setShowLogin: Function
}>({
  user: undefined,
  setUser: (user: MeeUser) => {},
  jwt: undefined,
  setJwt: (jwt: string) => {},
  logout: () => {},
  showLogin: false,
  setShowLogin: (showLogin: boolean) => {},
})
export const useUser = () => useContext(UserContext)
