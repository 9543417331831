import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'
import { DialogActions, DialogContentText, IconButton, Snackbar } from '@material-ui/core'
import 'react-phone-input-2/lib/style.css'
import { mutationBook } from 'queries'
import { useMutation } from 'react-query'
import { MeeMeet } from 'types'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import CircularProgressThemed from './circular-progress-themed'
import { useTranslation } from 'react-i18next'
import { RulesGenerator } from './rules'
import { DateTime } from 'luxon'
import { useUser } from 'contexts/userContext'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const ProcessBooking = ({ meet }: { meet: MeeMeet }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [open, setOpen] = React.useState(false)
  const [snackOpen, setSnackOpen] = React.useState(false)

  const [state, setState] = React.useState({
    isReadyForBooking: false,
    metadata: {},
  })

  const history = useHistory()

  React.useEffect(() => {
    setTimeout(() => {
      setOpen(true)
    }, 1500)
  }, [])

  const setComplete = (isReadyForBooking: boolean, metadata: any) => {
    // update the status
    setState({
      isReadyForBooking,
      metadata,
    })
  }

  const addRulesToMeet = () => {
    if (user && state.metadata) {
      meet.comments = [
        {
          date: DateTime.local().toMillis(),
          author: user._id, // get the user
          // the metadata we got back
          content: JSON.stringify(state.metadata),
        },
      ]
    }
  }

  const mutation = useMutation((meet: MeeMeet) => mutationBook(meet), {
    onSuccess: (data, variables, context) => {
      setSnackOpen(true)
      localStorage.removeItem('meet')
      // ID is overwritten by the backend when group
      // redirect to the ID returned by the backend
      // @ts-ignore
      history.push('/meet/' + data.data._id)
      handleClose()
    },
  })

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSnackClose = () => {
    setSnackOpen(false)
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        {t('component.processBooking.book')}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div>
          {mutation.isLoading ? (
            <React.Fragment>
              <DialogTitle id="alert-dialog-slide-title">
                {t('component.processBooking.title')}
              </DialogTitle>
              <CircularProgressThemed />
            </React.Fragment>
          ) : (
              <React.Fragment>
                <DialogTitle id="alert-dialog-slide-title">
                  {t('component.processBooking.confirm')}
                </DialogTitle>
                <DialogContent>
                  {mutation.data && !mutation.data.ok ? (
                    <DialogContentText id="alert-dialog-description">
                      {t('component.processBooking.error')} {mutation.data?.problem}
                    </DialogContentText>
                  ) : null}
                  <DialogContent>
                    <RulesGenerator businessId={meet.businessId} setComplete={setComplete} isReadyForBooking={state.isReadyForBooking} />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        addRulesToMeet()
                        mutation.mutate(meet)
                      }}
                      disabled={!state.isReadyForBooking}
                      autoFocus
                    >
                      {t('component.processBooking.confirmButton')}
                    </Button>
                    <Button onClick={() => handleClose()}>{t('component.login.cancel')}</Button>
                  </DialogActions>
                </DialogContent>
              </React.Fragment>
            )}
        </div>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={t('component.processBooking.done')}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  )
}
