import React from 'react'
import { Container, makeStyles, Grid, Paper, Typography, useTheme } from '@material-ui/core'
import { Business, Services, Steps } from 'components'
import AreaTitle from 'components/area-title'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(7),
    marginBottom: theme.spacing(10),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(5),
  },
}))

// @ts-ignore TODO: what type can we use here?
const BusinessDetail = (props) => {
  // const { t } = useTranslation();
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { businessId } = props.match.params
  const { t } = useTranslation()

  return (
    <Container fixed className={classes.container}>
      <Typography variant="h5" align="center">
        {t('book.title')}
      </Typography>
      <Steps current={0} businessId={businessId} />
      <Grid container spacing={isSmall ? 0 : 3}>
        <Grid item xs={12} md={6}>
          <AreaTitle>{t('book.business')}</AreaTitle>
          <Paper>
            <Business businessId={businessId} showEmployees />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <AreaTitle>{t('book.service')}</AreaTitle>
          <Paper>
            <Services businessId={businessId} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}

export default BusinessDetail
