export const config = {
  /**
   * Our API url
   */
  api: 'https://api.meetme.io',
  // home network
  // api: "http://localhost:3030",
  // api: "http://192.168.1.25:3030",
  // chamPhone on the road
  // api: "http://172.20.10.3:3030",
  // api: "http://192.168.0.55:3030",
  // Beta
  // api "https://api-beta.meetme.io",
  // Prod
  // api "https://api.meetme.io",

  /**
   * The images location
   */
  rokka: 'https://meetme-release.rokka.io',
  // rokka: "https://meetme.rokka.io",
}
