import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './i18n'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Router } from 'react-router-dom'
import ThemeProvider from 'components/ThemeProvider'
import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import ScrollToTop from 'components/scrollToTop'

const history = createBrowserHistory()

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.initialize('UA-131599646-1')
  ReactGA.set({ page: location.pathname }) // Update the user's current page
  ReactGA.pageview(location.pathname) // Record a pageview for the given page
})

ReactDOM.render(
  // Removed strict mode to fix an issue with Material UI (language switcher)
  // See issue and solution discussed here:
  // https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
  // <React.StrictMode>
  <Router history={history}>
    <ThemeProvider>
      <ScrollToTop />
      <App />
    </ThemeProvider>
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
