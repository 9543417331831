import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { constants } from '../constants'
const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: 'red',
    margin: 0,
    padding: 0,
  },
  download: {
    paddingBottom: theme.spacing(10),
  },
  downloadLinks: {
    flex: 1,
    flexDirection: 'column',
  },
  downloadImage: {
    padding: theme.spacing(3),
  },
  downloadImageApp: {
    flex: 1,
    // width: 400,
    width: '40%', // theme.breakpoints.up('sm'),
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}))

const DownloadApp = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <Box className={classes.container}>
      <Typography variant="h4">{t('home.download.title')}</Typography>
      <Grid
        container
        direction="row"
        justify="space-around"
        spacing={5}
        className={classes.download}
      >
        <Grid item container justify="center" xs={12} sm={6} className={classes.downloadLinks}>
          <a href={constants.storeAppleUrl}>
            <img
              className={classes.downloadImage}
              alt={t('home.download.android.subtitle')}
              src="/images/appStore-filled.png"
            />
          </a>
          <a href={constants.storeGoogleUrl}>
            <img
              className={classes.downloadImage}
              alt={t('home.download.android.subtitle')}
              src="/images/googlePlay-filled.png"
            />
          </a>
        </Grid>
        <Grid item container justify="center" xs={12} sm={6}>
          <img className={classes.downloadImageApp} src="/images/download-app.png" />
        </Grid>
      </Grid>
    </Box>
  )
}

export default DownloadApp
