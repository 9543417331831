import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { Login } from 'components'
import { useUser } from 'contexts/userContext'
const useStyles = makeStyles((theme) => ({
  download: {
    paddingBottom: theme.spacing(10),
  },
  downloadImage: {
    padding: theme.spacing(3),
  },
  errorIcon: {
    height: 90,
    width: 90,
    color: theme.palette.error.main,
  },
  gridContainer: {
    alignContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
  },
}))

/**
 * Handles errors
 *
 * Some errors are handled
 *
 * 1. Requires login
 * 2. JWT expires
 *
 * Other errors are displayed to the user.
 *
 */
const Error = ({ error }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { logout, setShowLogin } = useUser()

  // Not authenticated or JWT expired
  if (error?.code === 401) {
    logout()
  }

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      <Grid item xs={12} md={12}>
        <ErrorOutlineIcon className={classes.errorIcon} />
        <Typography variant="h2">{t('error.title')}</Typography>
        <Typography variant="h4">{t('error.subtitle')}</Typography>

        {/* <Divider /> */}
        {error?.message && <Typography variant="subtitle1">{error.message}</Typography>}
        {error?.code && <Typography variant="caption">{error.code}</Typography>}

        <p />
        <Button variant="contained" color="primary" onClick={() => setShowLogin(true)}>
          {t('profile.login')}
        </Button>

        {/* DEBUG */}
        {/* <Typography variant="caption">{JSON.stringify(error)}</Typography> */}
      </Grid>
    </Grid>
  )
}

export default Error
