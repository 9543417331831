import React from 'react'
import { ListItem } from '@material-ui/core'

/**
 * Render a listitem that it either editable or not
 */
export const LinkItemEditable = ({ editable, keyId, component, to, children }) => {
  if (editable) {
    return (
      <ListItem key={keyId} button component={component} to={to}>
        {children}
      </ListItem>
    )
  } else {
    return <ListItem key={keyId}>{children}</ListItem>
  }
}
