import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import AppsIcon from '@material-ui/icons/Apps'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import StarIcon from '@material-ui/icons/Star'
import EventIcon from '@material-ui/icons/Event'
import PeopleIcon from '@material-ui/icons/People'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import HistoryIcon from '@material-ui/icons/History'
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption'

const useStyles = makeStyles((theme) => ({
  download: {
    paddingBottom: theme.spacing(10),
  },
  downloadImage: {
    padding: theme.spacing(3),
  },
  errorIcon: {
    height: 90,
    width: 90,
    color: theme.palette.error.main,
  },
  gridContainer: {
    alignContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
  },
  icon: {
    fontSize: 40,
  },
}))

/**
 * Single advantage item
 */
const Advantage = ({ title, subtitle, children }) => {
  return (
    // xs={12} md={4} sm={6}
    <Grid item xs={12} md={4} sm={6}>
      {children}
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Grid>
  )
}

/**
 * Display advantages
 */
export const Advantages = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  const adv = [
    {
      Icon: <EmojiEmotionsIcon className={classes.icon} />,
      title: t('advantage.easyux.title'),
      subtitle: t('advantage.easyux.subtitle'),
    },
    {
      Icon: <AppsIcon className={classes.icon} />,
      title: t('advantage.flexible.title'),
      subtitle: t('advantage.flexible.subtitle'),
    },
    {
      Icon: <MonetizationOnIcon className={classes.icon} />,
      title: t('advantage.dashboard.title'),
      subtitle: t('advantage.dashboard.subtitle'),
    },
    {
      Icon: <SmartphoneIcon className={classes.icon} />,
      title: t('advantage.mobile.title'),
      subtitle: t('advantage.mobile.subtitle'),
    },
    {
      Icon: <NotificationsActiveIcon className={classes.icon} />,
      title: t('advantage.reminders.title'),
      subtitle: t('advantage.reminders.subtitle'),
    },
    {
      Icon: <PermContactCalendarIcon className={classes.icon} />,
      title: t('advantage.crm.title'),
      subtitle: t('advantage.crm.subtitle'),
    },
    {
      Icon: <StarIcon className={classes.icon} />,
      title: t('advantage.support.title'),
      subtitle: t('advantage.support.subtitle'),
    },
    {
      Icon: <EventIcon className={classes.icon} />,
      title: t('advantage.control.title'),
      subtitle: t('advantage.control.subtitle'),
    },
    {
      Icon: <PeopleIcon className={classes.icon} />,
      title: t('advantage.team.title'),
      subtitle: t('advantage.team.subtitle'),
    },
    {
      Icon: <ImportExportIcon className={classes.icon} />,
      title: t('advantage.report.title'),
      subtitle: t('advantage.report.subtitle'),
    },
    {
      Icon: <HistoryIcon className={classes.icon} />,
      title: t('advantage.liveSync.title'),
      subtitle: t('advantage.liveSync.subtitle'),
    },
    {
      Icon: <EnhancedEncryptionIcon className={classes.icon} />,
      title: t('advantage.secure.title'),
      subtitle: t('advantage.secure.subtitle'),
    },
  ]

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      {adv.map((item) => (
        <Advantage title={item.title} subtitle={item.subtitle}>
          {item.Icon}
        </Advantage>
      ))}
      {/* <Grid item xs={12} md={12}>
        <Typography variant="h2">{'title'}</Typography>
        <Typography variant="h4">{'subtitle'}</Typography>
      </Grid> */}
    </Grid>
  )
}

export default Advantages
