import React from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'
import { Footer, NavBar } from 'components'
import './App.css'
import { RootRouter } from 'Routes'
import { UserContextProvider } from 'contexts/userContextProvider'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
// move this to i18n package
import i18next from 'i18next'
import frLocale from 'date-fns/locale/fr'
import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-US'

// react-query client
const queryClient = new QueryClient()

const App = () => {
  // our Material UI locale
  const [muiLocale, setMuiLocale] = React.useState(frLocale)

  /**
   * Get the right language locales
   * Only fr, de or en are currently supported
   * @param language the language
   */
  const getLocale = (language) => {
    if (language.match(/fr/i)) {
      return frLocale
    } else if (language.match(/de/i)) {
      return deLocale
    } else {
      return enLocale
    }
  }

  React.useEffect(() => {
    const userNavigatorLanguage = navigator.languages[0]
    setMuiLocale(getLocale(userNavigatorLanguage))
    // on language switch
    i18next.on('languageChanged', (lng) => {
      setMuiLocale(getLocale(lng))
    })
  }, [])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={muiLocale}>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <div id="app" className="d-flex flex-column h-100">
            <NavBar />
            <div className="container flex-grow-1">
              <div className="mt-5">
                <RootRouter />
              </div>
            </div>
            <Footer />
          </div>
        </UserContextProvider>
      </QueryClientProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
