import React from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  List,
  CircularProgress,
  ListSubheader,
  Grid,
  makeStyles,
} from '@material-ui/core'
import { useQuery } from 'react-query'
import { MeeBusiness, MeeContact } from 'types'
import { User } from 'components'
import { queryBusiness, queryEmployees } from 'queries'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { config } from 'config'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(1),
  },
}))

// TODO: merge with user
const Employees = ({ businessId }: { businessId: string }) => {
  const { t } = useTranslation()
  const { data: contacts } = useQuery<MeeContact[], Error>(
    'employee',
    () => queryEmployees(businessId),
    {
      enabled: !!businessId,
    },
  )
  if (!businessId) return null
  return (
    <List>
      <ListSubheader>
        <Typography variant="subtitle2" align="left">
          {t('business.employees')}
        </Typography>
      </ListSubheader>
      {contacts?.map(
        (contact: MeeContact) =>
          contact.userId && <User key={contact.userId} userId={contact.userId} />,
      )}
    </List>
  )
}

export const Business = ({
  businessId,
  showEmployees,
}: {
  businessId: string
  showEmployees?: boolean
}) => {
  const { isLoading, data: business } = useQuery<MeeBusiness, Error>('business', () =>
    queryBusiness(businessId),
  )

  const classes = useStyles()

  if (!businessId) return null

  if (isLoading) {
    return <CircularProgress />
  }

  const image = `${config.rokka}/large/${business?.image?.hash}.jpg`

  const address = (business?: MeeBusiness) => {
    if (business && business?.address) {
      const { street, postal, city } = business.address
      // check we got all the info to concat them
      if (street && postal && city) {
        return (
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <LocationOnIcon fontSize="default" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" color="textSecondary">
                {`${street}, ${postal} ${city}`}
              </Typography>
            </Grid>
          </Grid>
        )
      }
      return null
    }
  }
  return (
    <Card>
      <CardMedia image={image} style={{ height: 0, paddingTop: '56.25%' /* 16:9 */ }} />
      <CardContent>
        <Typography gutterBottom variant="h5" align="left">
          {business?.name}
        </Typography>
        <Typography align="left" className={classes.description}>
          {business?.description}
        </Typography>
        {address(business)}
      </CardContent>
      {showEmployees && business && (
        <CardContent>
          <Employees businessId={business._id} />
        </CardContent>
      )}
    </Card>
  )
}
