import {
  Avatar,
  Chip,
  CircularProgress,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { queryBusiness, queryService } from 'queries'
import React from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { MeeBusiness, MeeService } from 'types'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import PaymentIcon from '@material-ui/icons/Payment'
import TimerIcon from '@material-ui/icons/Timer'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import EditIcon from '@material-ui/icons/Edit'
import { LinkItemEditable } from './link-item-editable'

const useStyles = makeStyles({
  description: {
    paddingBottom: 5,
  },
  chips: {},
})

export const Service = ({
  serviceId,
  to,
  businessId,
  editable,
  icon,
  group,
}: {
  serviceId: string
  to: string
  businessId: string
  editable: boolean
  icon?: React.ReactNode
  group?: boolean
}) => {
  const { isLoading, data: service } = useQuery<MeeService>(['service', serviceId], () =>
    queryService(serviceId),
  )
  const classes = useStyles()

  if (isLoading) {
    return <CircularProgress />
  }

  if (!serviceId) return null

  // workaround in case visible is not defined in the db for the given service
  const isVisible = (service) => {
    if (service.visible !== undefined) {
      return service.visible
    } else {
      return true
    }
  }

  const Price = ({ group, service }) => {
    if (!group && service?.price && service?.price !== 0) {
      return (
        <Chip
          size="small"
          // show the price with 2 decimals when applicable
          label={
            service?.price % 1 === 0
              ? service?.price + ' ' + service?.currency
              : service?.price.toFixed(2) + ' ' + service?.currency
          }
          icon={<PaymentIcon />}
        />
      )
    }
    return null
  }

  const MultipleEmployeeRequired = ({ service }) => {
    if (service?.numberOfEmployeeRequired && service?.numberOfEmployeeRequired > 1) {
      return <Chip size="small" label={'👥 ' + service?.numberOfEmployeeRequired} />
    }
    return null
  }

  // only show service that are set to visible by the business
  if (isVisible(service)) {
    return (
      <LinkItemEditable keyId={'lis' + service?._id} component={Link} to={to} editable={editable}>
        <ListItem>
          {service?.emoji && (
            <ListItemAvatar>
              <Avatar>{service?.emoji}</Avatar>
            </ListItemAvatar>
          )}
          <ListItemText
            primary={service?.title}
            secondary={
              <div>
                <div className={classes.description}>{service?.description}</div>
                <div className={classes.chips}>
                  <Chip size="small" label={service?.duration + 'min'} icon={<TimerIcon />} />
                  &nbsp;
                  <Price group={group} service={service} />
                  &nbsp;
                  <MultipleEmployeeRequired service={service} />
                </div>
              </div>
            }
          />
          {icon && (
            <ListItemAvatar>
              <Avatar>{icon}</Avatar>
            </ListItemAvatar>
          )}
        </ListItem>
      </LinkItemEditable>
    )
  } else {
    return null
  }
}

export const Services = ({ businessId }: { businessId: string }) => {
  const { isLoading, data: business } = useQuery<MeeBusiness, Error>('business', () =>
    queryBusiness(businessId),
  )

  if (isLoading) {
    return <CircularProgress />
  }

  if (!businessId) return null
  if (!business) return null

  const { serviceIds } = business
  return (
    <List>
      {serviceIds?.map((serviceId: string) => (
        <Service
          key={serviceId}
          businessId={business._id}
          serviceId={serviceId}
          editable={true}
          to={`/business/${businessId}/${serviceId}`}
          icon={<NavigateNextIcon />}
        />
      ))}
    </List>
  )
}
