import { AppBar, IconButton, Toolbar } from '@material-ui/core'
import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LanguageSwitcher from './language-switcher'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Profile from './profile'
import MeetmeLogo from '../assets/meetme-logo.svg'

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
  },
  logo: {
    width: 53,
    height: 53,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  link: {
    marginRight: theme.spacing(5),
  },
  languageSwitcher: {
    textAlign: 'right',
  },
}))

const NavBar = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  // home is now or new web site not in this app
  const goHome = useCallback(() => {
    window.location.href = 'https://www.meetme.io'
  }, [history])

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <div className={classes.menuButton}>
            <IconButton edge="start" aria-label="menu" onClick={goHome}>
              <img className={classes.logo} src={MeetmeLogo} alt={t('common.company')} />
            </IconButton>
          </div>
          <div className={classes.languageSwitcher}>
            <LanguageSwitcher />
          </div>
          <Profile />
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBar
