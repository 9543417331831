import React from 'react'
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  loading: {
    padding: theme.spacing(5),
    margin: theme.spacing(5),
  },
}))

const CircularProgressThemed = () => {
  const classes = useStyles()
  return <CircularProgress className={classes.loading} />
}

export default CircularProgressThemed
