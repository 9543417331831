import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
  },

  gridContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    textAlign: 'center',
    alignSelf: 'center',
  },
  text: {
    fontStyle: 'italic',
    textAlign: 'left',
    alignSelf: 'center',
  },
  pricing: {
    fontWeight: 'bold',
    textAlign: 'center',
    alignSelf: 'center',
  },
}))

export default function Pricing() {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Typography variant="h4">{t('component.pricing.title')}</Typography>
      <Grid container spacing={2} alignContent="center" className={classes.gridContainer}>
        <Grid item xs={4} md={4} sm={4}>
          <Typography variant="h2" className={classes.pricing}>
            49.-
          </Typography>
        </Grid>
        <Grid item xs={8} md={8} sm={8} className={classes.text}>
          <Typography>{t('component.pricing.text1')}</Typography>
          <Typography>{t('component.pricing.text2')}</Typography>
          <Typography>{t('component.pricing.text3')}</Typography>
        </Grid>
      </Grid>
    </>
  )
}
