import React from 'react'
import { Button, Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import { useQuery } from 'react-query'
import { queryBusiness } from 'queries'
import { MeeBusiness, MeeRules } from 'types'
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
  },
  item: {
    marginBottom: theme.spacing(2)
  }
}))

/**
 * We use the same mechanism on the meetme-web and the meetme-mobile
 * apps.
 */
const RuleItem = ({ rule, setComplete }: { rule: MeeRules; setComplete: Function }) => {
  const classes = useStyles()

  // 🔥 Our validation reviewed and enhanced on the mobile version
  const [formData, setFormData] = React.useState({})
  // TODO: use parent

  /**
   * Transform the JSON values to a more readable format
   * These will be displayed on the meet detail.
   */
  const cleanupFormData = (formData): string => {
    // loop through each JSON object props and create one item per line in a string
    let result = ''
    for (const [key, value] of Object.entries(formData)) {
      result += `${key}: ${value}
`
    }
    // remove quotes
    return result.replace(/^"|"$/g, '')
  }

  const checkFormValidity = () => {
    let isFormValid = true
    // check the formData based on the contents
    rule.contents.map((field, i) => {
      if (field.element === 'checkbox') {
        // checkbox needs to be in the data
        if (!formData[field.label]) {
          isFormValid = false
        }
        // need to check checkbox state?
      }
      // check input text
      if (field.element === 'input') {
        // if there is no data for this input
        if (!formData[field.label]) {
          isFormValid = false
        }
        // if there is data, check it's length
        if (formData[field.label] && formData[field.label].length < 3) {
          isFormValid = false
        }
      }
    })
    // callback to parent
    // set the form validity status to be able to complete the booking process
    // this is where we pass the data to the parent
    setComplete(isFormValid, cleanupFormData(formData))
  }

  // prevent a late state update
  // calling the setIsReadyForBooking function synchronously after the formData
  // state is updated, which can cause the delay you are experiencing.
  React.useEffect(() => {
    checkFormValidity()
  }, [formData])

  const handleChange = (event) => {
    const { value, type, name } = event.target
    if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: event.target.checked,
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }

  return (
    <form className={classes.form}>
      {rule?.contents?.map((content, index) => {
        if (content.element === 'text') {
          return <p className={classes.item} key={index}>{content.label}</p>
        } else if (content.element === 'input') {
          return (
            <TextField
              className={classes.item} 
              key={index}
              label={content.label}
              placeholder={content.placeholder}
              name={content.label}
              onChange={handleChange}
              required={true} // content.validation !== undefined}
            />
          )
        } else if (content.element === 'checkbox') {
          return (
            <FormControlLabel
              className={classes.item} 
              key={index}
              control={
                <Checkbox
                  name={content.label}
                  onChange={handleChange}
                  required={true} // content.validation !== undefined}
                />
              }
              label={content.label}
            />
          )
        }
      })}
    </form>
  )
}

export const RulesGenerator = ({
  businessId,
  setComplete,
  isReadyForBooking,
}: {
  businessId: string
  setComplete: Function
  isReadyForBooking: boolean
}) => {
  // get our business
  const { isLoading, data: business } = useQuery<MeeBusiness, Error>('business', () =>
    queryBusiness(businessId),
  )

  const { t } = useTranslation()

  if (isLoading) {
    return <CircularProgress />
  }

  // generate the form based on the rules set by the business
  if (business) {
    const { rules } = business
    // no rules to check, we can book immediately
    if (!rules) {
      if (!isReadyForBooking) {
        setComplete(true, null)
      }
    }
    return (
      <div>
        {rules?.map((rule, i) => (
          <RuleItem key={i} rule={rule} setComplete={setComplete} />
        ))}
        <Typography variant="body2" color="textSecondary" align="center">
          {t('component.processBooking.disclaimer')}
        </Typography>
      </div>
    )
  }
  return null
}
