import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { CrossLink, BusinessDetail, Book, Meet } from 'screen'

const Redirect = () => {
  // if not in dev mode, redirect to 
  if (process.env.NODE_ENV !== 'development') {
    window.location.href = 'https://www.meetme.io'
  }
  return null
}

export const RootRouter = () => {
  return (
    /**
     * First matching route will be rendered
     *
     * Don't forget the `apple-app-site-association` that is served via
     * setupProxy.js
     */
    <Switch>
      <Route path="/" exact component={Redirect} />
      <Route path="/x/" component={CrossLink} />
      <Route path="/business/:businessId/:serviceId" component={Book} />
      <Route path="/business/:businessId" component={BusinessDetail} />
      <Route path="/meet/:meetId?" component={Meet} />
      <Route path="*" component={() => <h1>Are you lost?</h1>} />
    </Switch>
  )
}
