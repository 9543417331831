import React from 'react'
import { Button, Container, makeStyles, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { DownloadApp } from 'components'
import { useHistory, Redirect } from 'react-router-dom'
import { constants } from '../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    alignContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    marginBottom: theme.spacing(10),
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(5),
  },
  iframe: {
    // visibility: 'hidden',
    height: 100,
    width: 100,
    backgroundColor: 'pink',
    // border: '1px solid black',
    borderColor: 'black',
    borderWidth: 1,
  },
}))

/**
 * This component will act depending on the platform (mobile or desktop) and
 * redirect to the desired app.
 *
 * On desktop, if the link is available for desktop, it will redirect to the
 * web version.
 *
 * If the link is not available for desktop, it will say to use the mobile
 * app.
 *
 * On mobile, it will try to open the app with the link. If the app does not open,
 * it will go to the app store to download the app.
 *
 * Tests
 * - Test on iOS with app installed
 * - Test on iOS with app *not* installed
 * - Test on Android with app installed
 * - Test on Android with app *not* installed
 * - Test on app while NOT LOGGED IN
 */
const CrossLink = (props) => {
  const classes = useStyles()

  // remove the leading "/x/" and add the appUrl
  const passedUrl: string = props.location?.pathname
  const builtUrl = 'meetme://' + passedUrl.substr(3, passedUrl.length)

  /**
   * Is mobile ? Thanks to
   * https://stackoverflow.com/questions/29773614/launch-app-from-link-if-no-app-then-go-to-download-app-from-web
   */
  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i)
    },
    any: function () {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      )
    },
  }

  /**
   * Returns true if the URL is supported on the web, otherwise false.
   * Useful to determine if we can redirect to the web version or if we
   * need to display a message forcing the user to download the app.
   * @param url the URL to check
   * @returns boolean
   */
  const isUrlWeb = (url: string) => {
    return url.match(/business/i) || url.match(/meet/i)
  }

  const transformUrlToWeb = (url: string) => {
    return url.replace('/x/', '/')
  }

  const YouNeedTheApp = () => {
    const { t } = useTranslation()

    return (
      <div>
        <Typography variant="h4" className={classes.title}>
          {t('crosslink.title')}
        </Typography>
        <Button className={classes.button} variant="contained" color="primary" href={builtUrl}>
          {t('crosslink.buttonOpenApp')}
        </Button>
        <Typography variant="body1" className={classes.description}>
          {t('crosslink.description')}
        </Typography>
        <DownloadApp />
      </div>
    )
  }

  // open the app and if still here after 3 seconds, redirect to the web version
  // TODO: show suggestion to download the app via a card or popup or something "a la twitter?"
  const TryOpenApp = ({ location }) => {
    const history = useHistory()
    const { t } = useTranslation()

    const goToStore = () => {
      if (isMobile.Android()) {
        window.location.href = constants.storeGoogleUrl
      } else if (isMobile.iOS()) {
        window.location.href = constants.storeAppleUrl
      } else {
        // show error for 'no-web-version and no-mobile-app'. Typically for Blackbery or other customer os
        // TODO: show need Android or iOS device
      }
    }

    const goToApp = () => {
      // try to open the URL for mobile device
      // on Safari this will show an error alert if the app is not installed
      window.location.href = builtUrl
    }

    const goToWeb = () => {
      window.location.href = transformUrlToWeb(history.location.pathname)
    }

    React.useEffect(() => {
      const timeout = isMobile.Android() ? 7000 : 500
      if (builtUrl) {
        goToApp()
        // but this timeout will dismiss it if still on the app
        setTimeout(function () {
          // fallback if the app is not installed
          // if the link is web-ready, got to it's version
          if (isUrlWeb(window.location.href)) {
            goToWeb()
          } else {
            // otherwise go to the proper store
            goToStore()
          }
        }, timeout)
      }
    }, [builtUrl])

    /**
     * When the user click to open the app, try to open the app and if not, go to the
     * appropriate store
     */
    const openAppOrGoToStore = () => {
      goToApp()
      setTimeout(() => goToStore(), 300)
    }

    return (
      <React.Fragment>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={openAppOrGoToStore}
          >
            {t('crosslink.buttonOpenApp')}
          </Button>
        </div>
        <div>
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            href={transformUrlToWeb(history.location.pathname)}
          >
            {t('crosslink.buttonOpenWeb')}
          </Button>
        </div>
      </React.Fragment>
    )
  }

  // our brain - the logic
  const RenderBasedOnContext = (props) => {
    const history = useHistory()
    if (isMobile.any()) {
      return <TryOpenApp {...props} />
    } else {
      // we are on desktop
      if (isUrlWeb(window.location.href) && history.location.pathname.indexOf('/x/') > -1) {
        // the URL is web compatible, let's redirect without the `x`
        return <Redirect to={{ pathname: transformUrlToWeb(history.location.pathname) }} />
      } else {
        // they need to download the app on their smartphone
        return <YouNeedTheApp {...props} />
      }
    }
  }

  return (
    <Container fixed className={classes.container}>
      <RenderBasedOnContext {...props} />
    </Container>
  )
}

export default CrossLink
