import React from 'react'
import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import { useTranslation } from 'react-i18next'
import FormatQuoteIcon from '@material-ui/icons/FormatQuote'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 15,
  },
  quotedTestimonial: {
    flex: 1,
    display: 'flex',
  },
  quote: {
    transform: 'rotateY(180deg)',
  },
  quote2: {
    marginTop: 'auto',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  testimonial: {
    padding: 7,
  },
  author: {},
  rating: {},
  gridContainer: {
    paddingBottom: theme.spacing(10),
    textAlign: 'center',
    alignSelf: 'center',
  },
}))

/**
 * Renders a testimonial from a user
 */
const Testimonial = ({ avatarSrc, avatarAlt, author, quote }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Avatar alt={avatarAlt} src={avatarSrc} className={classes.avatar} />
      <div className={classes.quotedTestimonial}>
        <FormatQuoteIcon className={classes.quote} fontSize="large" />
        <Typography variant="h5" className={classes.testimonial}>
          {quote}
        </Typography>
        <FormatQuoteIcon className={classes.quote2} fontSize="large" />
      </div>
      <Typography variant="subtitle1" className={classes.author}>
        {author}
      </Typography>

      <Rating name="read-only" value={5} readOnly className={classes.rating} />
    </div>
  )
}

export default function Testimonials() {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Typography variant="h4">{t('component.testimonials.title')}</Typography>
      <Grid container spacing={2} alignContent="center" className={classes.gridContainer}>
        <Grid item xs={12} md={6} sm={12}>
          <Testimonial
            avatarSrc="/testimonials/pedro.png"
            avatarAlt={t('component.testimonials.pedro.alt')}
            author={t('component.testimonials.pedro.author')}
            quote={t('component.testimonials.pedro.quote')}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={12}>
          <Testimonial
            avatarSrc="/testimonials/sibylle.png"
            avatarAlt={t('component.testimonials.sibylle.alt')}
            author={t('component.testimonials.sibylle.author')}
            quote={t('component.testimonials.sibylle.quote')}
          />
        </Grid>
      </Grid>
    </>
  )
}
