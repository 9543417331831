import React from 'react'
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import { useQuery } from 'react-query'
import { MeeBusiness } from 'types'
import { queryBusiness } from 'queries'
import { config } from 'config'

const useStyles = makeStyles((theme) => ({
  description: {
    marginBottom: theme.spacing(1),
  },
  cardMedia: {
    height: 50,
  },
}))

export const BusinessItem = ({ businessId }: { businessId: string }) => {
  const { isLoading, data: business } = useQuery<MeeBusiness, Error>('business', () =>
    queryBusiness(businessId),
  )

  const classes = useStyles()

  if (!businessId) return null

  if (isLoading) {
    return <CircularProgress />
  }

  const image = `${config.rokka}/large/${business?.image?.hash}.jpg`

  return (
    <Card>
      <CardMedia image={image} className={classes.cardMedia} />
      <CardContent>
        <Typography gutterBottom variant="h5" align="left">
          {business?.name}
        </Typography>
      </CardContent>
    </Card>
  )
}
