import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { DateTime } from 'luxon'
import { Comment } from '../types/types'
import AreaTitle from './area-title'
import { CircularProgress } from '@material-ui/core'
import { useQuery } from 'react-query'
import { queryUser } from 'queries'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 16,
    marginTop: 24,
  },
  card: {
    padding: theme.spacing(2),
  },
  cardTextContent: {
    maxWidth: '80%',
    marginBottom: 4,
    whiteSpace: 'pre-wrap',
  },
  cell: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  row: {
    // paddingBottom: 12,
  },
}))
export const UserAndDate = ({ userId, date }: { userId: string; date: string }) => {
  const { isLoading, data: user } = useQuery(['user', { userId }], () => queryUser(userId))
  // 40 is the default size of the thumbnail
  if (isLoading) {
    return <CircularProgress />
  }
  return (
    <Typography align="left" variant="subtitle2" color="textSecondary">
      – <strong>{user && `${user?.firstName}`}</strong> {date}
    </Typography>
  )
}

const CommentCard = ({ item, index }: { item: Comment; index: number }) => {
  const classes = useStyles()
  // TODO: grab contact and user and display first name
  return (
    <div className={classes.cell}>
      <Grid container className={classes.row}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.cardTextContent} align="left">
            {item.content.replace(/\\n/g, '\n').replace(/^"|"$/g, '')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <UserAndDate
            userId={item.author}
            date={DateTime.fromMillis(item.date).toRelative() + ''}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export const Comments = ({ comments }) => {
  const classes = useStyles()
  if (!comments) return null

  return (
    <Grid item xs={12} md={12}>
      <AreaTitle>Commentaires</AreaTitle>
      <Card className={classes.card}>
        {comments.map((commentsItem: Comment, index) => (
          <CommentCard item={commentsItem} index={index} />
        ))}
      </Card>
    </Grid>
  )
}
