import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { DownloadApp, ImageCard } from 'components'
import Advantages from 'components/advantages'
import Testimonials from 'components/testimonials'
import Pricing from 'components/pricing'

const useStyles = makeStyles((theme) => ({
  bookDemo: {
    alignContent: 'center',
    textAlign: 'center',
    paddingBottom: theme.spacing(10),
  },
  hero: {
    alignContent: 'center',
    textAlign: 'center',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(10),
  },
  heroTitle: {
    paddingBottom: theme.spacing(4),
  },
  heroSubtitle: {
    paddingBottom: theme.spacing(3),
  },
  heroCta: {
    textAlign: 'center',
  },
  cta: {},
  uvp: {
    paddingBottom: theme.spacing(10),
    textAlign: 'center',
    alignSelf: 'center',
  },
  download: {
    paddingBottom: theme.spacing(10),
  },
  downloadImage: {
    padding: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  link: {
    marginRight: theme.spacing(5),
  },
}))

const Home = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container fixed>
      <Box className={classes.hero}>
        <Typography variant="h2" className={classes.heroTitle}>
          {t('home.hero.title')}
        </Typography>
        <Typography variant="h4" className={classes.heroSubtitle}>
          {t('home.hero.subtitle')}
        </Typography>
      </Box>

      <Grid container spacing={2} alignContent="center" className={classes.uvp}>
        <Grid item xs={12} md={4} sm={6}>
          <ImageCard
            title={t('home.Welcome.reassuring1Title')}
            subtitle={t('home.Welcome.reassuring1Content')}
            image="images/welcome/1-square-350px.png"
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <ImageCard
            title={t('home.Welcome.reassuring2Title')}
            subtitle={t('home.Welcome.reassuring2Content')}
            image="images/welcome/2-square-350px.png"
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <ImageCard
            title={t('home.Welcome.reassuring3Title')}
            subtitle={t('home.Welcome.reassuring3Content')}
            image="images/welcome/3-square-350px.png"
          />
        </Grid>
      </Grid>

      <Grid className={classes.bookDemo}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/business/3eab01a0-e3bd-11ea-b2ff-2b47a7a099b5/9b750240-0317-11eb-af6b-75d44a27ca92"
          size="large"
        >
          {t('home.bookDemo')}
        </Button>
      </Grid>

      <Advantages />

      <Pricing />

      <Testimonials />

      <DownloadApp />
    </Container>
  )
}

export default Home
