import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(3),
  },
}))

const AreaTitle = ({ children }) => {
  const classes = useStyles()
  return (
    <Typography variant="subtitle2" color="textSecondary" align="left" className={classes.text}>
      {children}
    </Typography>
  )
}

export default AreaTitle
