import React from 'react'
import { Typography, Link, Grid, Box, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(6),
  },
  item: {
    paddingBottom: theme.spacing(3),
  },
}))

const Footer = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Grid container direction="row" justify="space-around">
        <Typography variant="body2" color="textSecondary" align="center">
          {t('footer.built')}
        </Typography>
      </Grid>
      <Grid container direction="row" justify="space-around" className={classes.item}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://www.meetme.io/">
            {t('common.company')}
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Grid>
    </Box>
  )
}

export default Footer
