import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTranslation } from 'react-i18next'
import { useUser } from 'contexts/userContext'
import PersonIcon from '@material-ui/icons/Person'
import { Login } from 'components'
export default function Profile() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const { t } = useTranslation()
  const { user, logout, setShowLogin } = useUser()

  const handleClick = () => {
    // show the login modal
    setShowLogin(true)
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  if (user) {
    return (
      <div>
        <Button
          startIcon={<PersonIcon />}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
        >
          {/* {user.firstName} */}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            {user.firstName} {user.lastName}
          </MenuItem>
          <MenuItem>{user.phone}</MenuItem>
          <MenuItem onClick={() => logout()}>{t('profile.logout')}</MenuItem>
        </Menu>
      </div>
    )
  } else {
    return (
      <div>
        <Button
          startIcon={<PersonIcon />}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {/* {t('profile.login')} */}
        </Button>
        <Login />
      </div>
    )
  }
}
